<template>
  <section class="gallery">
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Galerie</h4>
            <div class="row">
              <div class="col-12 mb-4 text-right">
                <button v-b-modal.addImage type="button" class="btn btn-social-icon btn-outline-facebook"><i class="mdi mdi-plus"></i></button>
                <!-- contenu du modal -->
                <b-modal id="addImage" title="Ajouter une image" size="md" hide-footer>

                  <form action="" @submit.prevent="submitFile">
                    <!-- importation image -->
                    <b-form-group label="Importer l'image" label-for="picture">
                      <b-form-file v-model="file" id="inpu8" :state="Boolean(file)" @change="onFileSelected" placeholder="Choisir ou glisser l'image ici"></b-form-file>
                    </b-form-group>
                    <p>selected File: {{file ? file.name : ''}}</p>

                    <!-- nom de l'image -->
                    <b-form-group label="Nom de l'image"  label-for="pictureName">
                      <b-form-input type="text" id="input5" placeholder="Entrer le nom de l'image" v-model="slugname"></b-form-input>
                    </b-form-group>

                    <!-- description de l'image -->
                    <b-form-group label="Description"  label-for="pictureDescription">
                      <b-form-textarea type="text" id="input5" placeholder="Entrer la description"></b-form-textarea>
                    </b-form-group>

                    <div class="d-flex justify-content-end">
                      <button class="btn btn-link">Annuler</button>
                      <button class="btn btn-gradient-primary" type="input">Ajouter l'image</button>
                    </div>
                  </form>
                </b-modal>
              </div>
            </div>
            <!-- <gallery :images="images" :index="index" @close="index = null"></gallery>
            <div
              class="image"
              v-for="(image, imageIndex) in images"
              :key="imageIndex"
              @click="index = imageIndex"
              :style="{ backgroundImage: 'url(' + image .url+ ')'}"
            >
            </div> -->

            <!-- image slide -->
            <gallery :images="sortedImages" :index="index" @close="index = null"></gallery>
            <!-- liste des images -->
            <!-- <div
              class="image"
              v-for="(image, imageIndex) in sortedImages"
              :key="imageIndex"
              @click="index = imageIndex"
              :style="{ backgroundImage: 'url(' + image + ')'}"
            >
            </div> -->

            <!-- portfolio -->
            <!-- <div class="row portfolio-grid">
              <div class="col-xl-3 col-lg-3 col-md-3 col-sm-6 col-12" v-for= "(image, imageIndex) in images" :key="imageIndex">
                <figure class="effect-text-in">
                  <img :src="image.url" alt="image" />
                  <figcaption>
                    <div>
                      <p class="d-flex justify-content-around">
                        <i class="fa fa-eye" @click="index = imageIndex"></i>
                        <i class="fa fa-trash" @click="deleteImage(image)"></i>
                      </p>
                    </div>
                  </figcaption>
                </figure>
              </div>
            </div> -->
            <div class="card-columns">
              <div class="card" v-for= "(image, imageIndex) in images" :key="imageIndex">
                <label title="Supprimer l'image" class="badge badge-danger crud delete pointer"  style="z-index:1" @click="showAlertDelete(image)"><i class="fa fa-trash"></i></label>
                <img class="card-img-top" :src="image.url" alt="Card image cap">
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
const axios= require('axios').default
  import VueGallery from 'vue-gallery';
  export default {
    name: 'dashboard-gallery',
    components: {
      'gallery': VueGallery
    },
      data: function () {
        return {
          imagesGallery: [
            require('../../../assets/images/samples/1280x768/1.jpg'),
            require('../../../assets/images/samples/1280x768/2.jpg'),
            require('../../../assets/images/samples/1280x768/3.jpg'),
            require('../../../assets/images/samples/1280x768/4.jpg'),
            require('../../../assets/images/samples/1280x768/5.jpg'),
            require('../../../assets/images/samples/1280x768/6.jpg'),
            require('../../../assets/images/samples/1280x768/7.jpg'),
            require('../../../assets/images/samples/1280x768/8.jpg'),
          ],
          index: null,
          file:null,
          pictureName:null,
          slugname:null,
          images:[]
        };
      },
      computed: {
        sortedImages () {
          let sortedImages = []
          for ( let image of this.images) {
            sortedImages.push (image.url)
          }
          return sortedImages
        }
      },
      methods: {
        getImages () {
          axios.get('imgs')
          .then (res=> {
            console.log('res Get images', res)
            // this.images= res.data.result
            this.images = res.data.result.filter(im => im.extension == "webp");
          })
          .catch (err => console.log(err))
        },
        onFileSelected (event) {
          // console.log(event)
          // this.file = this.$refs.file.files[0];
          this.file=event.target.files[0]
        },
        submitFile () {
          const fd = new FormData
          // fd.append ('source', this.file)
          // fd.append ('key', '6d207e02198a847aa98d0a2a901485a5')
          // fd.append ('action', 'upload')
          fd.append ('imgfile', this.file)
          fd.append ('alt', this.file)
          // fd.append ('slugname', this.slugname)
          // console.log(this.slugname)
          // console.log('image result', fd.values)
          // console.log(this.file.name)
          // console.log('file', this.file)
          axios.post('imgs', fd, 
          {
            headers: {
              'X-AUTH-TOKEN': localStorage.getItem('token'),
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(res=> {
            console.log('apiresponse',res)
            alert('Image ajoutée avec succès')
            this.getImages()
            })
          .catch(err=> console.log(err))
        },
        showAlertDelete(image){
          this.$swal({
            title: "Voulez-vous supprimer cette image?",
            text: "Cette image sera déliée de tous les catégories et produits",
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Supprimer',
            cancelButtonText: 'Annuler',
          })
          .then((result) => {
            if (result.value) {
              this.deleteImage(image)
              this.$swal(
                'Supprimé!',
                'L\'image a été supprimée avec succès',
                'success'
              )
            }
          })
        },
        deleteImage (image) {
          console.log(image);
          axios.delete(`imgs/${image.ulid}`,{
            headers: {
              'X-AUTH-TOKEN': localStorage.getItem('token')
            }
          })
          .then(resDeleteImage => {
            console.log(resDeleteImage)
            this.getImages()
            })
          .catch(errDeleteImage => console.log(errDeleteImage))
        }
      },
      mounted () {
        this.getImages()
      },
    }
</script>
<style scoped>

  .image {
    float: left;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border: 1px solid #ebebeb;
    margin: 15px;
    width: 22%;
    height: 220px;
  }
  @media (max-width: 1024px) {
    .image {
      width: 30%;
      margin: 1.5%;
      margin-bottom: 15px;
    }
  }
  @media (max-width: 767px) {
    .image {
      width: 100%;
      margin: 0;
      margin-bottom: 15px;
    }
  }

/* CRUD */
.crud.delete{
height: 40px;
width: 40px;
border-radius: 50%;
position: absolute;
right: -13px;
top: -20px;
padding-top: 10px;
font-size: 17px;
}
.card .crud {
    /* visibility: hidden; */
    display: none
}
.card:hover>.crud {
    /* visibility: visible; */
    display: inherit;
}
</style> 